@import url('fonts.css');

.headerlink {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    min-width: 400px;
    width: 70%;
    height: 200px;
    display: inline-block;
    overflow: visible;
    z-index: 2;
    position: relative;
}

.imgheader {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
    height: 300px;
}

.filterbar {
    justify-content: center;
    display: flex;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: var(--primary-dark);
    opacity: 95%;
    z-index: 2;
    position: relative;
}

.filterbutton {
    list-style-type: none;
    display: inline;
    padding: 10px;
    font-style: italic;
    font-size: 20px;
    z-index: 2;
    opacity: 100%;
}

.filterbutton a {
    color: var(--primary-light);
}

.filterbutton a:hover {
    color: var(--highlight-secondary);
}

.status {
    background-color: var(--secondary);
    position:relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    padding: 4px;
    opacity: 95%;
    white-space: pre-wrap;
    transition: opacity 0.5s ease-out 20ms;


}

.statusmessage {
    opacity: 100%;
    margin: auto 0;
    padding-left: 5px;
}

.sidebarbutton {
    margin: 1px 5px;
}

.stickyheader {
    position: sticky;
    z-index: 2;
    top: -1px;
}