#documentation {
    background-color: var(--neutral-light);
    margin: 0px;
    margin-bottom: 0px;
    padding-top: 15px;
}
.templatewrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: 20px 0vw;
    margin-top: 10px;
    padding-bottom: 20vh;
    background-color: var(--secondary)
}

.documainheader {
    text-align: center;
    margin: 20px 20vw;
    background-color: var(--primary-light);
    border: inset 10px var(--primary);
    padding: 15px 45px;
    border-radius: 120px 0px;
}

.dtext {
    padding: 5px;
    margin: 10px 12vw;
}

.dheader {
    margin-bottom: 2px;
    margin-top: 40px;
    font-size: xx-large;
}

.dboard {
    border: var(--secondary-dark);
    border-style: solid none;
    background-color: var(--neutral);
    margin: 20px 0;
    padding: 0 5vw;
    width: 90vw;
}

.dvid {
    margin: 10px 10vw;
    background-color: var(--highlight-secondary);
    max-height: 600px;
}

.dlink {
    font-style: italic;
}

.linkimg, .dimg {
    max-width: 60vw;
    padding: 5px;
    margin: 15px;
}
