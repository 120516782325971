.sidebar {
    position: fixed;
    height: 100%; 
    width: 250px;
    padding: 20px;
    right: -300px;
    top: 0;
    background-color: var(--primary-dark);
    /* border-left: solid 8px var(--primary-dark); */
    color: white;
    transition: right .3s ease-in-out;
    z-index: 5;
    border-left: solid 8px var(--primary)
}

.sidebar.open {
    right: 0;
    transition: right .3s ease-in-out;
}

.sidebar-contents {
    margin: 15px;
    margin-top: 0px;
}

.sortselect {
    padding: 0px 5px;
    margin: 0px 5px;
    /* background-color: var(--primary-light); */
    /* color: black; */
    text-align: left;
    /* border-radius: 0px 100px 0px 0px; */

}

.filterselect {
    padding: 0px 5px;
    margin: 0px 5px;
    /* background-color: var(--primary-light); */
    /* color: black; */
    text-align: left;
    /* border-radius: 0px 0 100px 0px; */
    /* border-radius: 100px; */

}


.checkmarkheader {
    margin-top: 12px;
    margin-bottom: 3px;
}


.topcheckmarkheader {
    margin-top: 3px;
}

.sectionheader {
    margin: 5px 0px;
    margin-top: 10px;
    text-align: left;
}

.typewrapbox, .industrywrapbox, .mediumwrapbox {
    display: flex;
    flex-flow: column wrap;
    height: min-content;
}

.insbbutton {
    margin: 3px;
    color: black;
}

.resetbtn {
    background-color: var(--primary-light);
}

.closebtn {
    background-color: var(--secondary);
}

.buttonbox {
    margin: 3px;
    padding: 3px;
    margin-top: 9px;
    display: flex;
    justify-content: space-between;

}

.searchbar {
    border-radius: 100px;
    border: none;
    padding: 5px 15px;
    width:190px;
}

.form-check, .form-radio {
    padding-left: 10px;
}