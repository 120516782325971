.entry {
    padding: 5px;
    margin: 5px;
    background-color: var(--neutral-dark);
    color: var(--secondary);
    max-height: 225px;
    transition: background-color 0.5s ease-in-out 20ms;
    position: relative;
}

.entry:hover {
    background-color: var(--primary-light);
}

.eTitle {
    position: absolute;
    margin: 5px;
    margin-right: 10px;
    background-color: var(--primary-light);
    font-size: 16px;
    /* box-shadow: 2px 2px 1px 1px #ffffffd3; */
    border-radius: 0 0 50px 0;
    transition: opacity 0.5s ease-in-out 20ms;
    z-index: 1;
}

/* features unique to individual */
.eTitle.indiv {
    color: black;
    opacity: 0%;
    border: solid 3px var(--primary-dark);
    padding: 4px 20px 3px 4px;
    text-align: left;
}

.entry:hover .eTitle.indiv {
    opacity: 100%;
}


.eContent {
    max-height: 215px;
}

.entryimg {
    max-height: inherit;
}



/* SUBPAGE LABEL */

.centertext {
    background-color:var(--primary-dark);
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    padding: 5px 5%;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 70%;
    z-index: 1;
    border-radius: 50px;
    transition: background-color 0.5s ease-in-out 20ms;


}

.eTitle.subpage {
    opacity: 100%;
    font-size: 18px;
    font-weight: bold;
    padding: 4px;
    margin: 0 auto;
    position: relative;
    background: none;
    transition: color 0.5s ease-in-out 20ms;
}

.ecaption {
    font-size: 16px;
    font-style: italic;
    transition: color 0.5s ease-in-out 20ms;
    margin: 0 10px;
}

.subpimg {
    filter: saturate(.4);
    transition: filter 0.5s ease-in-out 20ms;

}

.underline {
    text-decoration: none;
    background-image: linear-gradient(var(--primary-light), var(--primary-light));
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 2px;
    transition: 0.5s ease-in-out all 20ms;
  }

/* change color on hover: */

.entry:hover .centertext {
    background-color: var(--secondary-dark);

}
.entry:hover .subpage, .entry:hover .ecaption {
    color: var(--primary-light);
}

.entry:hover .subpimg {
    filter:saturate(1)
}

.entry:hover .underline {
    background-size: 100% 2px;
    /* some day when linear gradients are transitionable.... i will have color change */
    /* background-image: linear-gradient(var(--primary-light), var(--primary-light)); */
}
  